import React from "react"
import { Flex, chakra, Heading, Text, Link, Image } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

const GetWeld = () => {
  return (
    <Flex
      bg="white"
      px={{ base: "16px", md: "80px" }}
      py={{ base: "32px", md: "80px" }}
      flexDirection="column"
      my={{ base: "40px", md: "120px" }}
      position="relative"
    >
      <Heading
        fontSize={{ base: "30px", md: "64px" }}
        maxWidth="783px"
        letterSpacing="-0.03em"
      >
        Explored enough to launch your own spaceship?
        <br /> <chakra.span color="brandGreen">Get Weld!</chakra.span>
      </Heading>
      <Text maxW="375px" py={{ base: "12px", md: "24px" }}>
        Weld is completely free! You can use it on every desktop platform.
      </Text>
      <Flex position="relative">
        <Link
          as={GatsbyLink}
          to="/"
          fontWeight="medium"
          fontSize="14px"
          color="white"
          bg="brandGreen"
          px="32px"
          py="14px"
          display={{ base: "none", md: "block" }}
        >
          Download Weld Desktop App for Free
        </Link>
        <Flex
          bg="#ecf4f1"
          py="12px"
          px="14px"
          justifyContent="center"
          alignItems="center"
          display={{ base: "block", md: "none" }}
          borderRadius="3px"
        >
          <Text fontSize="14px" color="#86baa0">
            To download Weld, visit us from your computer
          </Text>
        </Flex>
        <Image
        position="absolute"
        left="400px"
        bottom="-30px"
          src="/images/arrow.svg"
          display={{ base: "none", md: "block" }}
        />
      </Flex>
      <Image
        position="absolute"
        right="10px"
        bottom="91px"
          src="/images/telescope.svg"
          display={{ base: "none", md: "block" }}
        />
        <Image
        mt="10px"
            w="288px"
          src="/images/telescope.svg"
          display={{ base: "block", md: "none" }}
        />
    </Flex>
  )
}

export default GetWeld
